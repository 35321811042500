import { ThemeProvider, createTheme } from '@material-ui/core/styles'
import { SplitClient, SplitContext } from '@splitsoftware/splitio-react'
import { useContext, useEffect, useState } from 'react'

import { getSplitKeyForUser } from '@percent/utility'
import { useAuthState } from '../common/hooks'
import { useChatWidget } from '../common/hooks/useChatWidget/useChatWidget'
import { NotificationBarContextController } from '../context/notificationBar/notificationBarController/NotificationBarContextController'
import { PromotionalPopupContextController } from '../context/promotionalPupup/promotionalPopupController/PromotionalPopupContextController'
import { goodstackPalette } from '../theme/theme'
import './App.scss'
import { AppProviders } from './providers/AppProviders'
import { getSplitKeyForAnonymousUser, splitSdkConfig } from './providers/SplitSdkProvider'
import { Routes } from './routes/Routes'
import { usePlausible } from '../common/hooks/usePlausible/usePlausible'

const theme = createTheme({
  palette: {
    primary: { main: goodstackPalette.primary400 },
    secondary: { main: goodstackPalette.black },
    text: {
      primary: `${goodstackPalette.black} !important`,
      secondary: `${goodstackPalette.gray600} !important`
    }
  },
  overrides: {
    MuiTableContainer: {
      root: {
        width: 'auto',
        padding: '24px',
        'overflow-x': 'hidden'
      }
    },
    MuiToolbar: {
      root: {
        paddingLeft: '0 !important'
      }
    },
    MuiTableCell: {
      root: {
        padding: '16px 0',
        fontSize: 14,
        borderBottom: 'none'
      },
      head: {
        color: `${goodstackPalette.black} !important`
      },
      body: {
        color: `${goodstackPalette.gray600} !important`
      },
      footer: {
        border: 'none'
      }
    },
    MuiDrawer: {
      paperAnchorDockedLeft: {
        borderRight: 'none',
        boxShadow: '0px 2px 16px rgba(5, 0, 56, 0.08) !important'
      }
    },
    MuiAppBar: {
      root: {
        flexDirection: 'row-reverse',
        backgroundColor: `${goodstackPalette.white} !important`,
        boxShadow: '0px 2px 16px rgba(5, 0, 56, 0.08) !important'
      }
    },
    MuiMenuItem: {
      root: {
        '&$selected': {
          backgroundColor: goodstackPalette.neutral100
        },
        fontFamily: 'Inter'
      }
    },
    MuiSelect: {
      root: {
        padding: '14px',
        border: 'none'
      },
      select: {
        '&:focus': {
          backgroundColor: goodstackPalette.white
        }
      }
    },
    MuiOutlinedInput: {
      root: {
        '&:hover $notchedOutline': {
          borderColor: goodstackPalette.black
        }
      }
    },
    MuiPaper: {
      root: {
        boxShadow: 'none !important'
      }
    },
    MuiDialog: {
      paperWidthLg: {
        maxWidth: '100% !important',
        maxHeight: '100% !important',
        width: '100% !important',
        height: '100% !important',
        margin: '0 !important'
      },
      paperWidthSm: {
        maxWidth: '560px !important'
      }
    }
  },
  typography: {
    fontFamily: ['Inter", "Arial", "Helvetica", "sans-serif"'].join(','),
    h4: {
      fontSize: 30,
      fontWeight: 400
    },
    h5: {
      fontSize: 24,
      fontWeight: 500
    },
    h6: {
      fontSize: 20,
      fontWeight: 500
    },
    subtitle1: {
      fontSize: 18
    },
    subtitle2: {
      fontSize: 16
    },
    body1: {
      fontSize: 14
    },
    body2: {
      fontSize: 12
    },
    button: {
      textTransform: 'none'
    }
  }
})

export function App() {
  const {
    authState: { user }
  } = useAuthState()

  const [configKey, setConfigKey] = useState(splitSdkConfig.core.key)
  const { isReady } = useContext(SplitContext)

  useEffect(() => {
    if (isReady) {
      const key = getSplitKeyForUser({ email: user?.email, id: user?.id || getSplitKeyForAnonymousUser() })

      if (configKey !== key) {
        setConfigKey(key)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, isReady])

  useChatWidget()
  usePlausible()

  return (
    <SplitClient splitKey={configKey} updateOnSdkTimedout>
      <AppProviders>
        <ThemeProvider theme={theme}>
          <PromotionalPopupContextController>
            <NotificationBarContextController>
              <Routes />
            </NotificationBarContextController>
          </PromotionalPopupContextController>
        </ThemeProvider>
      </AppProviders>
    </SplitClient>
  )
}
