import { NotificationBar } from '@percent/cause-dashboard/common/components/notification/NotificationBar'
import { useNotificationBar } from '@percent/cause-dashboard/common/hooks/useNotificationBar/useNotificationBar'
import { config } from '@percent/cause-dashboard/config'
import { SideMenu } from '../sideMenu/SideMenu'
import { TopBar } from '../topbar/TopBar'
import styles from './DashboardLayout.module.scss'

interface DashboardLayoutProps {
  children: React.ReactNode
}

export const DashboardLayout = ({ children }: DashboardLayoutProps) => {
  const notification = useNotificationBar()
  const shiftDownToAccomodateNotification = notification.isOpened ? 48 : 0
  const minHeightConditionalOnNotification = notification.isOpened ? 'calc(100vh - 48px)' : '100vh'

  return (
    <div className={styles.container}>
      {<NotificationBar config={config} />}
      <div style={{ minHeight: minHeightConditionalOnNotification }}>
        <div className={styles.sideMenuWrapper} style={{ top: shiftDownToAccomodateNotification }}>
          <SideMenu />
        </div>

        <div className={styles.pageWrapper} style={{ marginTop: shiftDownToAccomodateNotification }}>
          <TopBar />
          <div className={styles.pageContent}>{children}</div>
        </div>
      </div>
    </div>
  )
}
