import { Grid } from '@material-ui/core'
import styles from './SplitPagesContainer.module.scss'
import { TitleAndBullets } from '@percent/cause-dashboard/common/components/TitleAndBullets'
import { DETAIL_BULLET_POINTS_DEFAULT, detailTitle } from '@percent/cause-dashboard/constants/emailVerificationData'
import { useTranslation } from 'react-i18next'
import { Footer } from '@percent/cause-dashboard/common/components/footer/Footer'
import { LocaleKey } from '@percent/cause-dashboard/i18n/config'
import { CausesPortalLogo } from '@percent/cause-dashboard/common/components/causesPortalLogo/CausesPortalLogo'

interface SplitPagesContainerProps {
  children: React.ReactNode
  bullets?: { title: LocaleKey }[]
}

export const SplitPagesContainer = ({ children, bullets = DETAIL_BULLET_POINTS_DEFAULT }: SplitPagesContainerProps) => {
  const { t } = useTranslation()

  return (
    <Grid container spacing={0} className={styles.container}>
      <Grid item sm={12} md={6} className={styles.leftScreen}>
        <CausesPortalLogo />
        {children}
        <Footer />
      </Grid>
      <Grid item sm={12} md={6} className={styles.titleAndBullets}>
        <TitleAndBullets title={t(detailTitle)} bullets={bullets} />
      </Grid>
    </Grid>
  )
}
